import axios from "@/axios";

export default {
  fetchAppUsers({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/v1/admins/fetchUserList?page=" +
            data.page +
            "&limit=" +
            data.limit +
            "&search=" +
            data.search +
            "&sort=" +
            data.sort +
            "&dir=" +
            data.dir +
            "&userType=" +
            data.userType +
            "&user=" +
            data.user
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchAppUserDetails({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/admins/appUserDetails/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchAppUserIndex({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/getUserIndex")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateAppUserDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/admins/appUserDetails/update/" + data.id, data.info)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  saveAppUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/register", data.info)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  deleteAppUser({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/api/v1/deleteUser/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  resetUserPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/admins/user/" + data.id + "/resetPassword", data.data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },

  fetchAccountCreationLogs({ commit }, data) {
    return new Promise((resolve, reject) => {
      let fetchLogs =
        "/api/v1/accountCreationLogs?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir +
        "&filter=" +
        data.filter;
   
      axios
        .get(fetchLogs)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },

  getAccountCreationLogs({ commit }, data) {
    return axios
      .post("/api/v1/getCreationLogs", data)
      .then(res => {
        return res.data; 
      })
      .catch(err => {
        throw err; 
      });
  }
  
};
